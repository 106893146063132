import './Header.css';
import React, { useState } from 'react';

function Header(props) {

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  function calcOpacity(max) {
    return Math.min((isActive ? 1 : (parseFloat(props.scrollOffset)) / (100)), max)
  }

  return (
    <div id="container" className={isActive ? 'navOpen': null} style={{backgroundColor: `rgba(6,27,45,${calcOpacity(1)})`, borderBottom: `2px solid rgba(255, 255, 255, ${calcOpacity(0.3)})`}}>
      <div className="content-container">
      <div id="logo-wrapper" onClick={() => props.setPage("ranking")}>
        elo-tt
      </div>
      <div id="burger-button-wrapper">
        <div id="burger-container" className={isActive ? 'change': null} onClick={toggleClass} >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      <div id="nav-item-wrapper">
        <div className="nav-item">
          <div className="nav-item-text" onClick={() => {props.setPage("ranking"); setActive(false);}}>
            Ranking
          </div>
          <div className={"nav-item-icon " + (props.currentPage === 'ranking' ? "selected" : "")}>
            <img src="icons/trophy.svg" alt="trophy-icon"/>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-item-text" onClick={() => {props.setPage("calculator"); setActive(false);}}>
            Rechner
          </div>
          <div className={"nav-item-icon " + (props.currentPage === 'calculator' ? "selected" : "")}>
            <img src="icons/calculator.svg" alt="trophy-icon"/>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-item-text" onClick={() => {props.setPage("data"); setActive(false);}}>
            Data
          </div>
          <div className={"nav-item-icon " + (props.currentPage === 'data' ? "selected" : "")}>
            <img src="icons/question.svg" alt="trophy-icon"/>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-item-text" onClick={() => {props.setPage("contact"); setActive(false);}}>
            Kontakt
          </div>
          <div className={"nav-item-icon " + (props.currentPage === 'contact' ? "selected" : "")}>
            <img src="icons/email.svg" alt="trophy-icon"/>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Header;