import './Table-Entry.css';
import React, { useState, useEffect } from 'react';
import Graph from './Graph';

function TableEntry(props) {

    const [isExpanded, setExpanded] = useState(false);

    function classNameEloDiff() {
        let base = "elo-diff";
        if (props.eloDiff > 0) {
            return base + " plus";
        } else if (props.eloDiff < 0) {
            return base + " minus";
        } else {
            return base;
        }
    }

    function classNameRankDiff() {
        let base = "rank-diff-icon";
        if (props.rankDiff > 0) {
            return base + " arrow-up";
        } else if (props.rankDiff < 0) {
            return base + " arrow-down";
        } else {
            return base;
        }
    }

    function valueRankDiff() {
        if (props.rankDiff > 0) {
            return props.rankDiff;
        } else if (props.rankDiff < 0) {
            return (0 - props.rankDiff);
        }
    }

    function valueEloDiff() {
        if (props.eloDiff > 0) {
            return "+" + props.eloDiff;
        } else if (props.eloDiff < 0) {
            return props.eloDiff;
        }
    }

    const toggleClass = () => {
        if(!isExpanded) {
        }
        setExpanded(!isExpanded);
    };

    return (
        <div className="table-entry-container" onClick={() => toggleClass()}>
            <div className="rank">
                {props.rank}
            </div>
            <div className="rank-diff-wrapper">
                <div className={classNameRankDiff()}>

                </div>
                <div className="rank-diff-text">
                    {valueRankDiff()}
                </div>
            </div>
            <div className="name-club-wrapper">
                <div className="name">
                    {props.name}
                </div>
                <div className="club">
                    {props.club}
                </div>
            </div>
            <div className="elo">
                {props.elo}
            </div>
            <div className={classNameEloDiff()}>
                {valueEloDiff()}
            </div>
            {isExpanded &&
                <div className="graph-container">
                    <Graph license={props.license}/>
                </div>
            }
        </div>
    );
}

export default TableEntry;