import React, { useState, useEffect } from 'react';
import './Calculator.css';
import IframeResizer from 'iframe-resizer-react'
import Sponsors from './Sponsors';

function Calculator(props) {

    return (
        <div className="container">
        <IframeResizer
            src="/calculator/index.html"
            style={{ width: '1px', minWidth: '100%', border: '0px' }}
        />
        <Sponsors />
        </div>
    );
}

export default Calculator;