import './Contact.css'

function Contact(props) {

    return (
        <div className="contact-container">
            <div className="contact-infos">
                Die neusten Elo-Updates werden jeweils am 10. des Monats veröffentlicht. <br></br>
                Fehler, Feedback und Sponsoring-Angebote gerne per Mail an:
            </div>
            <div className="impressum">
                Patrick Züst <br></br>
                <span className="small"> TTC Bremgarten </span><br></br>
                <a href="mailto:elo-tt@outlook.com">elo-tt@outlook.com</a> <br></br>
                <a href="https://www.patrickzuest.com" target="_blank">www.patrickzuest.com </a> <br></br>
                <span className="extra-small">© 2021</span>
            </div>
        </div>
    );
}

export default Contact;