import React, { useState } from 'react';
import C1 from '../res/c_1.png';
import C2 from '../res/c_2.png';
import C3 from '../res/c_3.png';
import C4 from '../res/c_4.png';
import './Sponsors.css';

function Sponsors(props) {

    return (
        <div></div>
        // <div className="sponsors-container">
        //     <div className="logo-wrapper">
        //     <h3>Sponsoren</h3>
        //     <img className="sponsor-image" src={C1} />
        //     <img className="sponsor-image" src={C3} />
        //     <img className="sponsor-image" src={C2} />
        //     <img className="sponsor-image" src={C4} />
        //     </div>
        // </div>
    );
}

export default Sponsors;