import './Ranking.css';
import TableEntry from './Table-Entry';
import Filter from './Filter';
import Graph from './Graph';
import React, { useState, useEffect } from 'react';
import Sponsors from './Sponsors';

function Ranking(props) {

    const [entries, setEntries] = useState([]);
    const [offset, setOffset] = useState(100);
    const [url, setUrl] = useState("");
    const [moreEntries, setMoreEntries] = useState(true);

    function fetchNextEntries() {
        setOffset(offset + 100);
        console.log(url);
        console.log(offset);
        let newUrl = url;
        newUrl += "offset=" + (offset + 100);

        fetch(newUrl)
            .then(response => response.json())
            .then(data => {
                let temp_entries = [];
                data.forEach(row => {
                    temp_entries.push(<TableEntry key={row.license} license={row.license} rank={row.rank} rankDiff={row.rankdiff} name={row.firstname + " " + row.lastname} club={row.club} elo={row.elo} eloDiff={row.elodiff} />)
                });
                setEntries(entries.concat(temp_entries));
            });
    }


    // useEffect(() => {
    //     window.onscroll = () => {
    //         if(window.scrollY == window.document.body.offsetHeight - window.innerHeight) {
    //             console.log(url)
    //         }
    //       }
    // }, []);


    function fetchUpdates(params) {
        let newUrl = "/api/ranking2?offset=0&";
        Object.entries(params).map(([key, value]) => newUrl += `${key}=${value}&`);

        fetch(newUrl)
            .then(response => response.json())
            .then(data => {
                let temp_entries = [];
                data.forEach(function(row, i) {
                    if(i==10){
                        temp_entries.push(<Sponsors key={"sponsors"}/>)
                    }
                    temp_entries.push(<TableEntry key={row.license} license={row.license} rank={row.rank} rankDiff={row.rankdiff} name={row.firstname + " " + row.lastname} club={row.club} elo={row.elo} eloDiff={row.elodiff} />)
                });
                setEntries(temp_entries);
                if (data.length < 100) {
                    setMoreEntries(false);
                } else {
                    setMoreEntries(true);
                }
            });
    }

    return (
        <div className="ranking-container">
            <Filter updateFunction={fetchUpdates} saveUrl={setUrl} setOffset={setOffset} />
            {entries}
            {moreEntries &&
                <div className="button-container">
                    <button onClick={fetchNextEntries}>
                        mehr
                    </button>
                </div>
            }
        </div>
    );
}

export default Ranking;