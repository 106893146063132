import './App.css';
import Calculator from './components/Calculator';
import Header from './components/Header';
import Ranking from './components/Ranking';
import Contact from './components/Contact';
import Sponsors from './components/Sponsors';
import React, { useState, useEffect } from 'react';
import Playground from './components/Playground';

function App() {

  const [page, setPage] = useState("ranking");

  const [scrollY, setScrollY] = useState(0);

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    // Remove listener (like componentWillUnmount)
    return () => {
      window.removeEventListener("scroll", logit);
    };
  }, []);


  return (
    <div className="App">
      <header className="App-header">
        <div className="photoTest" style={{backgroundImage: `url(./res/bg-${page}.png)`}}>
          <div className="headerContainer">
            <Header currentPage={page} setPage={setPage} scrollOffset={scrollY}/>
          </div>
          {page == "calculator" &&
            <div className="titleTexty">Elo-Rechner</div>
          }
          {page == "ranking" &&
            <div className="titleTexty">Schweizer<br></br> <div style={{width:"100%", display:"flex", flexWrap:"wrap", justifyContent:"center"}}> Tischtennis <div style={{marginLeft:"20px"}}> Ranking</div></div></div>
          }
          {page == "data" &&
            <div className="titleTexty">Data Playground</div>
          }
          {page == "contact" &&
            <div className="titleTexty">Kontakt</div>
          }
        </div>
        {page == "calculator" &&
          <Calculator />
        }
        {page == "ranking" &&
          <Ranking />
        }
        {page == "data" &&
          <Playground />
        }
        {page == "contact" &&
          <Contact />
        }
      </header>
    </div>
  );
}

export default App;
