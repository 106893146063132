import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import React, { useState, useEffect } from 'react';
import Club_History_Element from './Club_History_Element';

function Graph(props) {
    const [playerHistory, setPlayerHistory] = useState([]);
    const [allValues, setAllValues] = useState([]);
    const [clubHistory, setClubHistory] = useState([]);

    useEffect(() => {
        fetchHistory()
    }, []);

    function fetchHistory() {
        if (!props.license) {
            return
        }
        let lastClub;
        fetch("/api/playerhistory?players=" + props.license)
            .then(response => response.json())
            .then(data => {
                let valuesForGraph = []
                let valuesForClubs = []
                let allValues = []
                let entries = data[0]
                let hasPlayed = false;
                Object.keys(entries).forEach(key => {
                    if (key.startsWith("e_")) {
                        let myName = key.slice(2)
                        if (myName.slice(4) == "01") {
                            myName = myName.slice(0, 4);
                        }
                        if (entries[key] != null) {
                            allValues.push(entries[key])
                        }
                        valuesForGraph.push({
                            name: myName,
                            p0: entries[key]
                        })
                    }
                    if (key.startsWith("c_")){
                        lastClub = key;
                        if (key.endsWith("06")) {
                            if(entries[key] == null && hasPlayed == false) {
                                return;
                            }
                            hasPlayed = true
                            let myName = key.slice(2)
                            myName = myName.slice(2, 4);
                            let myYear = "20" + (parseInt(myName) - 1) + "/" + myName;
                            valuesForClubs.push({
                                year: myYear,
                                club: entries[key]
                            })
                        }
                    }
                })
                if(lastClub.endsWith("06") == false) {
                    let month = parseInt(lastClub.slice(6))
                    let year = lastClub.slice(4,6)
                    let myYear;
                    if(month < 6) {
                        myYear = "20" + (parseInt(year) - 1) + "/" + year;
                    } else if(month > 6) {
                        myYear = "20" + year + "/" + (parseInt(year) + 1);
                    }
                    valuesForClubs.push({
                        year: myYear,
                        club: entries[lastClub]
                    })
                }
                setAllValues(allValues)
                setPlayerHistory(valuesForGraph)
                let temp_entries = []
                valuesForClubs.forEach(entry => {
                    temp_entries.push(<Club_History_Element key={entry.year} year={entry.year} club={entry.club} />)
                });
                setClubHistory(temp_entries)
            }
            );
    }

    function calcYTicks() {
        let stepSize = (Math.max(...allValues) - Math.min(...allValues)) / 4;
        let i = 1;
        let arr = [Math.min(...allValues) - 2]
        while (i < 5) {
            arr.push(Math.floor(Math.min(...allValues) + (i * stepSize) + 2))
            i++
        }
        return arr
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', height: '200px', flexWrap: 'wrap' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={playerHistory}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="5 5 5" />
                        <XAxis dataKey="name" ticks={["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"]} tick={{ fill: 'grey', fontSize: '15px' }} />
                        <YAxis domain={[Math.min(...allValues) - 2, Math.max(...allValues) + 2]} tick={{ fill: 'grey', fontSize: '15px' }} ticks={calcYTicks()} />
                        <Line type="monotone" dataKey="p0" stroke="#8884d8" activeDot={{ r: 8 }} dot={false} strokeWidth={5} stroke={"rgb(144,219,235)"} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-around", margin: '0px 20px' }}>
                {clubHistory}
            </div>
        </div>
    )
}

export default Graph