import React, { useState, useEffect, useRef } from 'react';
import './Playground.css';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import Sponsors from './Sponsors';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const defaultMaterialTheme = createTheme();

function Playground(props) {

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const tableRef = React.createRef();
  const [pageSize, setPageSize] = useState(40)
  const [selectionLevel, setSelectionLevel] = useState("clubs")
  const [season, setSeason] = useState("2022")

  useEffect(() => {
    fetchUpdates();
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    tableRef.current?.dataManager?.changePageSize(pageSize);
  });

  function fetchUpdates(event) {
    let mySeason = season
    let mySelectionLevel = selectionLevel
    if(event) {
      if(isNaN(event.target.value)) {
        mySelectionLevel = event.target.value;
        setSelectionLevel(event.target.value);
      } else {
        mySeason = event.target.value;
        setSeason(mySeason);
      }
    }
    let newUrl = `/api/${mySelectionLevel}?season=${mySeason}`;

    fetch(newUrl)
      .then(response => response.json())
      .then(data => {
        let temp_data = []

        data.forEach(row => {
          let allFields = Object.keys(row);
          let temp_row = {}
          allFields.forEach(field => {
            temp_row[field] = row[field];
            if (field.startsWith('p_') && row[field] == null) {
              temp_row[field] = '0%';
            }
          })
          temp_data.push(temp_row)
        });

        setTableData(temp_data);
        setPageSize(temp_data.length)

        let all_columns = []
        Object.keys(data[0]).forEach(key => {
          let type = 'string'
          let title = key;
          let mycustomSort = null;
          if (title.startsWith('n_')) {
            title = title.slice(2);
            type = 'numeric'
          }
          if (title.startsWith('p_')) {
            title = title.slice(2);
            mycustomSort = (a, b) => {
              if (a.p_frauen == null) { return 1 }
              if (b.p_frauen == null) { return -1 }
              if (parseInt(a.p_frauen?.slice(0, -1)) > parseInt(b.p_frauen?.slice(0, -1))) {
                return 1
              } else {
                return -1
              }
            };
          }
          title = title.charAt(0).toUpperCase() + title.slice(1);
          all_columns.push({ title: title, field: key, type: type, customSort: mycustomSort })
        })

        setTableColumns(all_columns)
      });
  }

  return (
    <div className="table-container">
      <div className="selectContainer">
      <select onChange={fetchUpdates}>
      <option value="clubs">Vereine</option>
        <option value="association">Verbände</option>
        <option value="transfers">Transfers</option>
      </select>
      <select onChange={fetchUpdates}>
        <option value="2022">2022/23</option>
        <option value="2021">2021/22</option>
        <option value="2020">2020/21</option>
        <option value="2019">2019/20</option>
        <option value="2018">2018/19</option>
        <option value="2017">2017/18</option>
        <option value="2016">2016/17</option>
        <option value="2015">2015/16</option>
        <option value="2014">2014/15</option>
      </select>
      </div>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          tableRef={tableRef}
          icons={tableIcons}
          key={13}
          title=""
          columns={tableColumns}
          data={tableData}
          options={{ thirdSortClick: false, exportButton: true, draggable: false, pageSizeOptions: [Array(300)]}}
        />
      </ThemeProvider>
      <Sponsors />
    </div>
  );
}

export default Playground;