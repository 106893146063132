import React, { useState } from 'react';

function Club_History_Element(props) {

  return (
    <div style={{margin: '5px', border: '2px solid rgba(255,255,255,0.3)', padding: '5px', borderRadius: '10px', cursor:'default'}}>
      <div style={{fontSize: '12px', color: 'rgba(255,255,255, 0.6)', fontWeight: 'bold'}}>
        {props.year}
      </div>
      <div style={{fontSize: '15px'}}>
        {props.club}
      </div>
    </div>
  );
}

export default Club_History_Element;