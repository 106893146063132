import React, { useState, useEffect } from 'react';
import './Filter.css';
import { RangeSlider } from 'rsuite';

// import default style
import 'rsuite/dist/styles/rsuite-default.css';


function Filter(props) {

    const months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
    const maxDate = [6, 23];
    const minDate = [6, 14];

    const [isExpanded, setExpanded] = useState(true);
    const [sliderValues, setSliderValues] = useState([0, 2500]);
    const [selectedGender, setGender] = useState("");
    const [params, setParams] = useState({ currentDate: '202306', compareDate: '202305' });
    const [currentDate, setCurrentDate] = useState([6, 23]);
    const [compareDate, setCompareDate] = useState([5, 23]);
    const [compareDateChanged, setCompareDateChanged] = useState(false);

    useEffect(() => {
        props.updateFunction(params, props.setUrlFunction);
        let newUrl = "/api/ranking2?";
        Object.entries(params).map(([key, value]) => newUrl += `${key}=${value}&`);
        props.saveUrl(newUrl);
        props.setOffset(0);

        // const onScroll = (event) => {
        //     if(window.scrollY == window.document.body.offsetHeight - window.innerHeight) {
        //         console.log(offset)
        //         setOffsetFunc()
        //     }
        // }
          
    }, []);

    function updateParams(key, value) {
        let temp_params = params;
        temp_params[key] = value;
        setParams(temp_params);
        props.updateFunction(params, props.setUrlFunction);
        let newUrl = "/api/ranking2?";
        Object.entries(params).map(([key, value]) => newUrl += `${key}=${value}&`);
        props.saveUrl(newUrl);
        props.setOffset(0);
    }

    function updateMultipleParams(arr) {
        let temp_params = params;
        arr.forEach(el => { temp_params[el.key] = el.value })
        setParams(temp_params);
        props.updateFunction(params, props.setUrlFunction);
        let newUrl = "/api/ranking2?";
        Object.entries(params).map(([key, value]) => newUrl += `${key}=${value}&`);
        props.saveUrl(newUrl);
        props.setOffset(0);
    }

    function handleInput(event, key) {
        updateParams(key, event.target.value);
    }

    function handleGenderSelection(value) {
        setGender(value);
        updateParams('gender', value);
    }

    function handleSlider() {
        updateParams('minElo', sliderValues[0]);
        updateParams('maxElo', sliderValues[1]);
    }

    function cmpDates(d1, d2, op) {
        if (op === 'l') {
            return (d1[1] < d2[1] || (d1[1] === d2[1] && d1[0] < d2[0]))
        } else if (op === 'le') {
            return (d1[1] < d2[1] || (d1[1] === d2[1] && d1[0] <= d2[0]))
        } else if (op === 'g') {
            return (d1[1] > d2[1] || (d1[1] === d2[1] && d1[0] > d2[0]))
        } else if (op === 'ge') {
            return (d1[1] > d2[1] || (d1[1] === d2[1] && d1[0] >= d2[0]))
        } else {
            return false;
        }
    }

    function canChangeCurrentDate(change) {
        let temp_date = [(currentDate[0] + change[0]), (currentDate[1] + change[1])];
        if (temp_date[0] === 0) { temp_date[0] = 12; temp_date[1] -= 1; }
        if (temp_date[0] === 13) { temp_date[0] = 1; temp_date[1] += 1; }
        if (cmpDates(temp_date, maxDate, 'le') && cmpDates(temp_date, minDate, 'g')) {
            return [true, temp_date];
        }
        return [false, []];
    }

    function z(val) {
        return val >= 10 ? val : ("0" + val)
    }

    function changeCurrentDate(change) {
        let result = canChangeCurrentDate(change);
        if (result[0]) {
            console.log("compareDateChanged: " + compareDateChanged);
            setCurrentDate(result[1]);
            if (!compareDateChanged || cmpDates(result[1], compareDate, 'le')) {
                let temp_date = [(result[1][0] - 1), (result[1][1])];
                if (temp_date[0] === 0) { temp_date[0] = 12; temp_date[1] -= 1; }
                if (temp_date[0] === 13) { temp_date[0] = 1; temp_date[1] += 1; }
                setCompareDate(temp_date);
                updateMultipleParams([{ key: 'compareDate', value: `20${temp_date[1]}${z(temp_date[0])}` }, { key: 'currentDate', value: `20${result[1][1]}${z(result[1][0])}` }]);
            } else {
                updateParams('currentDate', `20${result[1][1]}${z(result[1][0])}`);
            }
        }
    }

    function canChangeCompareDate(change) {
        let temp_date = [(compareDate[0] + change[0]), (compareDate[1] + change[1])];
        if (temp_date[0] === 0) { temp_date[0] = 12; temp_date[1] -= 1; }
        if (temp_date[0] === 13) { temp_date[0] = 1; temp_date[1] += 1; }
        if (cmpDates(temp_date, maxDate, 'l') && cmpDates(temp_date, minDate, 'ge') && cmpDates(temp_date, currentDate, 'l')) {
            return [true, temp_date];
        }
        return [false, []];
    }

    function changeCompareDate(change) {
        let result = canChangeCompareDate(change);
        if (result[0]) {
            setCompareDate(result[1]);
            updateParams('compareDate', `20${result[1][1]}${z(result[1][0])}`);
            setCompareDateChanged(true);
        }
    }

    function monthToText(num) {
        return months[num - 1];
    }

    const toggleClass = () => {
        setExpanded(!isExpanded);
    };

    return (
        <div className={`Filter ${isExpanded ? 'Filter-expanded' : ''}`}>
            {/* <div className="Filter-show-more-wrapper" onClick={() => toggleClass()}>
                <img src="icons/expand.svg" alt="expand-icon" />
            </div> */}
            <div className="Filter-input-wrapper">
                <div className="Filter-input-element">
                    <div className="Filter-gender-wrapper">
                        <div className={`Filter-gender-element Filter-gender-element-double ${selectedGender === '' ? 'Filter-gender-element-selected' : ''}`} onClick={() => handleGenderSelection("")}>
                            <img src="icons/male.svg" alt="female-icon" className="male-icon" />
                             /
                            <img src="icons/female.svg" alt="female-icon" className="female-icon" />
                        </div>
                        <div className={`Filter-gender-element ${selectedGender === 'male' ? 'Filter-gender-element-selected' : ''}`} onClick={() => handleGenderSelection("male")}>
                            <img src="icons/male.svg" alt="male-icon" className="male-icon" />
                        </div>
                        <div className={`Filter-gender-element ${selectedGender === 'female' ? 'Filter-gender-element-selected' : ''}`} onClick={() => handleGenderSelection("female")}>
                            <img src="icons/female.svg" alt="female-icon" className="female-icon" />
                        </div>
                    </div>
                </div>
                <div className="Filter-input-element">
                    <div className="Filter-searchbox">
                        <div className="Filter-searchbox-icon">
                            <img src="icons/search.svg" alt="search icon"></img>
                        </div>
                        <input className="Filter-input-text" type="text" placeholder="Name" spellCheck="false" onChange={e => handleInput(e, 'name')} />
                    </div>
                </div>
                <div className="Filter-input-element">
                    <div className="Filter-date-wrapper">
                        <div className="Filter-date-label">
                            Stand:
                    </div>
                        <div className={"Filter-date-arrow " + (!canChangeCurrentDate([0, -1])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/double_arrow.svg" alt="double_arrow" className="Filter-year-back" onClick={() => changeCurrentDate([0, -1])} />
                        </div>
                        <div className={"Filter-date-arrow " + (!canChangeCurrentDate([-1, 0])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/single_arrow.svg" alt="single_arrow" className="Filter-month-back" onClick={() => changeCurrentDate([-1, 0])} />
                        </div>
                        <div className="Filter-date-text Filter-date-text-month">
                            {monthToText(currentDate[0])}
                        </div>
                        <div className="Filter-date-text Filter-date-text-year">
                            {currentDate[1]}
                        </div>
                        <div className={"Filter-date-arrow " + (!canChangeCurrentDate([1, 0])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/single_arrow.svg" alt="single_arrow" className="Filter-month-forward" onClick={() => changeCurrentDate([1, 0])} />
                        </div>
                        <div className={"Filter-date-arrow " + (!canChangeCurrentDate([0, 1])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/double_arrow.svg" alt="double_arrow" className="Filter-year-forward" onClick={() => changeCurrentDate([0, 1])} />
                        </div>
                    </div>
                </div>
                <div className="Filter-input-element">
                    <div className="Filter-searchbox">
                        <div className="Filter-searchbox-icon">
                            <img src="icons/search.svg" alt="search icon"></img>
                        </div>
                        <input className="Filter-input-text" type="text" placeholder="Verein" spellCheck="false" onChange={e => handleInput(e, 'club')} />
                    </div>
                </div>
                <div className="Filter-input-element">
                    <div className="Filter-select-wrapper">
                        <div className="Filter-select-label">
                            Verband:
                        </div>
                        <div className="Filter-selectbox">
                            <select className="Filter-input-select" onChange={e => handleInput(e, 'region')}>
                                <option value="">Alle</option>
                                <option value="AGTT">AGTT</option>
                                <option value="ANJTT">ANJTT</option>
                                <option value="ATTT">ATTT</option>
                                <option value="AVVF">AVVF</option>
                                <option value="MTTV">MTTV</option>
                                <option value="NWTTV">NWTTV</option>
                                <option value="OTTV">OTTV</option>
                                <option value="TTVI">TTVI</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="Filter-input-element">
                    <div className="Filter-date-wrapper">
                        <div className="Filter-date-label">
                            Vergleich:
                    </div>
                        <div className={"Filter-date-arrow " + (!canChangeCompareDate([0, -1])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/double_arrow.svg" alt="double_arrow" className="Filter-year-back" onClick={() => changeCompareDate([0, -1])} />
                        </div>
                        <div className={"Filter-date-arrow " + (!canChangeCompareDate([-1, 0])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/single_arrow.svg" alt="single_arrow" className="Filter-month-back" onClick={() => changeCompareDate([-1, 0])} />
                        </div>
                        <div className="Filter-date-text Filter-date-text-month">
                            {monthToText(compareDate[0])}
                        </div>
                        <div className="Filter-date-text Filter-date-text-year">
                            {compareDate[1]}
                        </div>
                        <div className={"Filter-date-arrow " + (!canChangeCompareDate([1, 0])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/single_arrow.svg" alt="single_arrow" className="Filter-month-forward" onClick={() => changeCompareDate([1, 0])} />
                        </div>
                        <div className={"Filter-date-arrow " + (!canChangeCompareDate([0, 1])[0] ? 'Filter-date-disabled' : '')}>
                            <img src="icons/double_arrow.svg" alt="double_arrow" className="Filter-year-forward" onClick={() => changeCompareDate([0, 1])} />
                        </div>
                    </div>
                </div>
                {/* <div className="Filter-input-element">
                    <div className="Filter-slider">
                        <RangeSlider onMouseUp={handleSlider} onChange={setSliderValues} className="Filter-input-slider" min={0} max={2500} defaultValue={[0, 2500]} />
                        <div className="Filter-input-slider-label-wrapper">
                            <div className="Filter-input-slider-label">
                                {sliderValues[0]}
                            </div>
                            <div className="Filter-input-slider-label">
                                {sliderValues[1]}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>


            {/* <div className="test" onClick={() => updateFilter()}>Click me</div>
            <input type="text" onChange={e => handleInput(e, 'club')} /> */}
        </div>
    );
}

export default Filter;